import React from 'react'
import './Footer.scss'
import '../../shared/Global.scss'
export const Footer = () => {
  return (
    <footer id="footer">
      <p className='fontsans'>© William La 2022</p>  
    </footer>
  )
}
